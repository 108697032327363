<template>
  <div class="horizontal-layout footer-fixed px-md-3 p-1">
    <b-row class="mb-2">
      <b-col lg="6" md="8" sm="10" cols="9">
        <mindfuli-logo />
      </b-col>
      <b-col
        lg="6"
        md="4"
        sm="2"
        cols="3"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button class="mr-1" pill variant="outline-primary" @click="login">
          Login
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3 ">
      <b-col cols="12">
        <router-view />
      </b-col>
    </b-row>

    <footer class="footer footer-light">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import { BRow, BCol, BLink,BButton} from "bootstrap-vue";
import MindfuliLogo from "@core/layouts/components/Logo.vue";
import AppFooter from "@core/layouts/components/AppFooter.vue";

export default {
  components: {
    AppFooter,
    BButton,
    BRow,
    BCol,
    BLink,
    MindfuliLogo,
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
  },
}
</script>

<style lang="scss">
@import "./src/@core/scss/base/themes/bordered-layout.scss";

.footer-clear{
  padding-bottom:3.35rem;
}
</style>